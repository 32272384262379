/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.ant-drawer-inline {
  position: absolute;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
.ant-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all 0.3s;
}
.ant-drawer-content-wrapper-hidden {
  display: none;
}
.ant-drawer-left > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-top > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom > .ant-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ant-drawer-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.ant-drawer-extra {
  flex: none;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-title {
  flex: 1;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}
.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active,
.ant-drawer-mask-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter,
.ant-drawer-mask-motion-appear {
  opacity: 0;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active {
  opacity: 1;
}
.ant-drawer-mask-motion-leave {
  opacity: 1;
}
.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start,
.ant-drawer-panel-motion-left-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active,
.ant-drawer-panel-motion-left-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start,
.ant-drawer-panel-motion-right-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active,
.ant-drawer-panel-motion-right-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start,
.ant-drawer-panel-motion-top-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active,
.ant-drawer-panel-motion-top-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start,
.ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active,
.ant-drawer-panel-motion-bottom-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-skeleton {
  padding-top: 16px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}

